<template>
  <label class="font-weight-bold">Карта доступности сервиса:</label>
  <div id="map" class="map-container"></div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  props: ['districts'],
  setup(props) {
    const map = ref(null);

    const createMap = () => {
      if (map.value) {
        // Очистка карты от предыдущих слоев, если они есть
        map.value.eachLayer((layer) => {
          if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
            map.value.removeLayer(layer);
          }
        });
      } else {
        // Инициализация карты, если она не была создана
        map.value = L.map('map').setView([43.2210, 76.8512], 12); // Установите начальную позицию карты

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map.value);
      }

      // Добавляем полигоны для всех районов
      Object.values(props.districts).forEach((coords) => {
        const polygon = L.polygon(coords, { color: 'blue', weight: 2 }).addTo(map.value);
        map.value.fitBounds(polygon.getBounds());
      });
    };

    onMounted(() => {
      createMap();
    });

    watch(() => props.districts, () => {
      if (map.value) {
        createMap();
      }
    }, { deep: true });

    return {};
  }
};
</script>

<style>
.map-container {
  height: 500px; /* Установите высоту контейнера карты */
  width: 100%;   /* Установите ширину контейнера карты */
  border-radius: 5px;
}
</style>
