<template>
  <div class="container mt-5">

    <!-- Отображаем количество строк -->
      <div class="form-group">
        <label class="font-weight-bold">Всего заказов:</label>
        <p class="border p-2 price-field">{{ totalRows }}</p>
      </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      totalRows: 0, // Общее количество строк
      loading: true // Статус загрузки данных
    };
  },
  methods: {
    async fetchTotalRows() {
      let totalRecords = 0; // Переменная для подсчета строк
      let offset = null; // Для отслеживания пагинации

      try {
        // Ваш ключ API и ID базы данных Airtable
        const apiKey = 'pattmB435fO3XJZkm.eab56e2d9a9ef77c6439aaf32d645d799da29ae777f105bfb6237207bef068c8'; // Вставьте ваш API ключ Airtable
        const baseId = 'app3EobWIwwWyd5y3'; // Вставьте ID вашей базы
        const tableName = 'tbl2s9hXZpUCW3XHP'; // Название таблицы в Airtable

        // Цикл для получения всех строк через пагинацию
        do {
          // Запрос с параметром offset (если есть)
          const response = await axios.get(`https://api.airtable.com/v0/${baseId}/${tableName}`, {
            headers: {
              Authorization: `Bearer ${apiKey}`
            },
            params: {
              offset: offset // передаем offset для получения следующей страницы
            }
          });

          // Увеличиваем количество строк
          totalRecords += response.data.records.length;

          // Если есть offset, продолжаем запросы, иначе завершаем цикл
          offset = response.data.offset;
        } while (offset);

        // Сохраняем общее количество строк
        this.totalRows = totalRecords;
      } catch (error) {
        console.error('Ошибка при получении данных из Airtable:', error);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    // Вызываем метод при загрузке компонента
    this.fetchTotalRows();
  }
};
</script>

<style>
/* Стили для отображения */
.container {
  max-width: 600px;
}
</style>
