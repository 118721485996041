<template>
  <div id="app">
    <MapView :districts="districts" />
    <CounterRecord />
    <OrderForm />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import OrderForm from './components/OrderForm.vue';
import CounterRecord from './components/CounterRecord.vue';
import MapView from './components/MapView.vue'; // Убедитесь, что импортируете MapView

export default {
  components: {
    OrderForm,
    CounterRecord,
    MapView
  },
  setup() {
    const districts = ref({});

    onMounted(async () => {
      try {
        const response = await fetch('/coordinates.json');
        const data = await response.json();
        
        // Преобразуйте данные в формат долготы и широты
        const formatCoordinates = (coords) => {
          return coords.map(([lat, lon]) => [lon, lat]); // Меняем местами широту и долготу
        };

        // Примените преобразование к координатам для всех районов
        const formattedDistricts = {};
        for (const [district, coords] of Object.entries(data.districts)) {
          formattedDistricts[district] = formatCoordinates(coords);
        }

        // Установите данные для всех районов
        districts.value = formattedDistricts;
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    });

    return { districts };
  }
};
</script>

<style>
/* Ваши стили */
</style>
