<template>
  <div class="container mt-5">
    <h5 class="mb-4 text-center">Создать заказ</h5>
    
    <form @submit.prevent="submitForm" class="bg-light p-4 rounded shadow-sm">
      <!-- Поле ввода телефона с маской -->
      <div class="form-group">
        <label for="phone" class="font-weight-bold">Номер телефона:</label>
        <input type="text" v-model="form.phone" id="phone" class="form-control" placeholder="Введите номер телефона" required>
      </div>

      <!-- Выпадающее меню для выбора типа строения -->
      <div class="form-group">
        <label for="buildingType" class="font-weight-bold">Тип строения:</label>
        <select v-model="form.buildingType" id="buildingType" class="form-control" @change="checkApartment">
          <option value="Квартира">Квартира</option>
          <option value="Частный дом">Частный дом</option>
        </select>
      </div>

      <!-- Поле для ввода номера квартиры, показывается только если выбрано "Квартира" -->
      <div v-if="showApartmentNumber" class="form-group">
        <label for="apartmentNumber" class="font-weight-bold">Номер квартиры:</label>
        <input type="number" v-model="form.apartmentNumber" id="apartmentNumber" class="form-control" placeholder="Введите номер квартиры">
      </div>

      <!-- Поле для ввода адреса -->
      <div class="form-group">
        <label for="address" class="font-weight-bold">Адрес:</label>
        <input type="text" v-model="form.address" id="address" class="form-control" placeholder="Введите адрес" required>
      </div>

      <!-- Выпадающее меню для выбора типа мусора -->
      <div class="form-group">
        <label for="wasteType" class="font-weight-bold">Тип мусора:</label>
        <select v-model="form.wasteType" id="wasteType" class="form-control" @change="updatePrice">
          <option value="Обычный мусор">Обычный мусор</option>
          <option value="Сортированный мусор">Сортированный мусор</option>
          <option value="Строительный мусор">Строительный мусор</option>
        </select>
      </div>

      <!-- Поле для отображения стоимости услуги -->
      <div class="form-group">
        <label class="font-weight-bold">Стоимость услуги:</label>
        <p class="border p-2 price-field">{{ price }} тенге</p>
      </div>

      <!-- Кнопка отправки формы -->
      <button type="submit" class="btn btn-primary btn-block">Создать заказ</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        phone: '',               // Номер телефона
        buildingType: 'Квартира', // Тип строения по умолчанию
        apartmentNumber: '',      // Номер квартиры
        address: '',              // Адрес
        wasteType: 'Обычный мусор' // Тип мусора по умолчанию
      },
      price: 490, // Стоимость по умолчанию
      showApartmentNumber: true // Отображение поля для номера квартиры
    };
  },
  methods: {
    // Обновление стоимости в зависимости от типа мусора
    updatePrice() {
      if (this.form.wasteType === 'Сортированный мусор') {
        this.price = 890;
      } else if (this.form.wasteType === 'Обычный мусор') {
        this.price = 490;
      } else if (this.form.wasteType === 'Строительный мусор') {
        this.price = 1390;
      }
    },
    // Проверка, нужно ли отображать поле номера квартиры
    checkApartment() {
      this.showApartmentNumber = (this.form.buildingType === 'Квартира');
      if (this.form.buildingType !== 'Квартира') {
        this.form.apartmentNumber = ''; // Очищаем значение номера квартиры, если выбран "Частный дом"
      }
    },
    // Отправка данных формы в Airtable
    async submitForm() {
      try {
        // Если выбран "Частный дом", устанавливаем apartmentNumber в null
        const apartmentNumber = this.form.buildingType === 'Частный дом' ? null : this.form.apartmentNumber;

        const apiKey = 'pattmB435fO3XJZkm.eab56e2d9a9ef77c6439aaf32d645d799da29ae777f105bfb6237207bef068c8'; // Вставьте ваш API ключ Airtable
        const baseId = 'app3EobWIwwWyd5y3'; // Вставьте ID вашей базы
        const tableName = 'tbl2s9hXZpUCW3XHP'; // Название таблицы в Airtable

        const record = {
          fields: {
            Phone: this.form.phone,
            BuildingType: this.form.buildingType,
            Address: this.form.address,
            ApartmentNumber: apartmentNumber,
            WasteType: this.form.wasteType,
            Price: this.price
          }
        };

        await axios.post(`https://api.airtable.com/v0/${baseId}/${tableName}`, record, {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        });

        alert('Заказ успешно создан!');
        window.location.reload();

      } catch (error) {
        console.error('Ошибка при отправке данных в Airtable:', error);
        alert('Ошибка при создании заказа. Попробуйте ещё раз.');
      }
    }
  }
};
</script>



<style>
/* Стили для формы */
.container {
  max-width: 600px;
}

.price-field {
  border-radius: 5px; /* Закругление углов */
}
</style>
